import { DispatchEventModel, DispatchingAlertCreatedRecord, EmployeeModel, EquipmentResourceModel } from '../../Models/models';
import { OthersToEditProps } from '../Modals/ModalsManager';

export enum DispatchingModalEnum {
  None,
  Accessories,
  ExtendContinuity,
  PostponeOT,
  SubcontractOT,
  NoSubcontracting,
  TransferOT,
  ReplaceOperator,
  ReplaceEquipment,
  AssignOperator,
  PreferredOperators,
  ConfirmDepartureTimeChange,
  Unavailabilities,
  RequestServiceCallModification,
  AdvanceOT,
  ConfirmOrdreDeTravail,
  GeneralNotes,
}

export const OPEN_CONFIRM_CONTINUITY_MODAL = Symbol('open_confirm_continuity_modal');
export type PubSubOpenConfirmContinuityModalEvent = { modalCallback: (updateContinuity: boolean) => void };

export const OPEN_DISPATCHING_RESOURCE_MODAL = Symbol('open_dispatching_resource_modal');
export type PubSubOpenDispatchingResourceModalEvent = { resourceRecord: EquipmentResourceModel; modal: DispatchingModalEnum };

export const OPEN_DISPATCHING_MODAL = Symbol('open_dispatching_modal');
export type PubSubOpenDispatchingModalEvent = { eventRecord?: DispatchEventModel; others?: OthersToEditProps; modal: DispatchingModalEnum };
export type PubSubOpenDispatchingModalEventWithEmployee = PubSubOpenDispatchingModalEvent & { employee: EmployeeModel };

export const CLOSE_DISPATCHING_MODAL = Symbol('close_dispatching_modal');
export type PubSubCloseDispatchingModalEvent = { eventRecordId?: string; resourceId?: string };

export const HIGHLIGHT_EVENT_RECORD = Symbol('highlight_event_record');
export type PubSubHighlightEvent = { eventRecord: DispatchEventModel };

export const HIGHLIGHT_DISPATCHING_ALERT_EVENT = Symbol('highlight_dispatching_alert_event');
export type PubSubHighlightDispatchingAlertEvent = { dispatchingAlert: DispatchingAlertCreatedRecord };

export const SCROLL_TO_RESOURCE = Symbol('scroll_to_resource');
export type PubSubScrollToResourceEvent = { preferredEquipmentTypeCode: number; preferredCapacity: number };

export const OPEN_CODE_DE_FACTURATION_NOT_DISPATCHED_MODAL = Symbol('open_code_de_facturation_not_dispatched_modal');
export type PubSubOpenCodeDeFacturationNotDispatchedModalEvent = { date: string; ordreDeTravailId: string; serviceCallId?: string };

export const ABORT_DRAG = Symbol('abort_drag');
export type PubSubAbortDragEvent = { eventId: string };
