import {
  ContractAgreementStatus,
  CraneOperatorKind,
  DailyTimeSheetStatus,
  JobCode,
  OrdreDeTravailStatus,
  OrdreDeTravailValidationStatus,
  ServiceCallKind,
  TimeSheetEntryStatus,
  WorkKind,
  WorkType,
} from '../../generated/schemas';
import { EntityTypeErrorEnum } from '../components/page/ErrorMessageHelpers';

const button = {
  add: 'Add',
  cancel: 'Cancel',
  close: 'Close',
  confirm: 'Confirm',
  continue: 'Continue',
  return: 'Return',
  returnToPreviousPage: 'Return to previous page',
  save: 'Save',
  yes: 'Yes',
  no: 'No',
  edit: 'Edit',
  replace: 'Replace',
  refresh: 'Refresh',
  create: 'Create',
  submit: 'Submit',
  viewMore: 'View more',
  viewLess: 'View less',
  done: 'Done',
};

const navigation = {
  dispatch: 'Dispatch',
  acknowledgements: 'Acknowledgements',
  contractAgreements: 'Contract agreements',
  timesheets: 'Timesheets',
  validations: 'Validations',
  ordresDeTravail: 'Ordres de travail',
  configuration: 'Configuration',
};

const autocomplete = {
  clearText: 'Clear',
  noOptions: 'No more options',
  select: 'Select',
};

const saveResult = {
  save_validation: 'Data is invalid, correct errors to save.',
  save_success: 'Saved successfully.',
  save_error: 'An error occurred while saving. Please try again.',
  save_skip: 'No changes detected.',
  save: 'Saved',
  submit_error: 'An error occurred while submitting. Please try again.',
};

const attachmentResult = {
  no_uri: 'The attachment was not found.',
  upload_error_one: 'One attachment was refused during the upload process.',
  upload_error_other: '{{count}} attachments were refused during the upload process.',
};

const serverConnectionErrorPanel = {
  title: 'Server not responding',
  content: 'Check your Internet connexion or ',
  contactAdmin: 'Contact your administrator.',
  refreshButtonLabel: 'Retry',
};

const commonTableToolbar = {
  resetSortAndFiltersButton: 'Reset Sort/Filters',
  resetColumnsButton: 'Reset Columns',
};

const gridFooter = {
  lastUpdatedAt: 'Data refreshed on {{date}} at {{time}}',
};

const savingErrorModal = {
  title: 'Saving failed!',
  message: 'An error occurred while saving. Please try Again.',
};

const cancelConfirmModal = {
  title: 'Warning',
  cancel: 'Cancel',
  confirm: 'Confirm',
};

const timeRangePicker = {
  from: 'From',
  to: 'To',
  select: 'Select',
};

const weekPicker = {
  reset: 'Remove dates',
  select: 'Select a week',
};

const somethingWentWrong = {
  somethingWentWrong: 'Something went wrong',
};

const cancelEditWarningModal = {
  title: 'Edits not saved',
  content: 'If you navigate outside this page, your edits will be lost.',
  quit: 'Quit',
  stayOnEdit: 'Stay on page',
  saveAndQuit: 'Save and quit',
};

const workTypeWithCode = {
  [WorkType.Industrial]: '29 - Industrial (Isolated James Bay)',
  [WorkType.RegularLabor]: '30 - Regular Work',
  [WorkType.IndustrialSite]: '31 - Industrial Site',
  [WorkType.HeavyIndustries]: '32 - Heavy industries',
  [WorkType.Pipeline]: '33 - Pipeline',
  [WorkType.PowerLineErection]: '34 - Electrical line - Erection',
  [WorkType.GeneralElectricPostLine]: '35 - Substation Electrical - General Line',
  [WorkType.CivilEngineeringAndRoads]: '36 - Civil Engineering and Roads',
  [WorkType.WindTurbine]: '37 - Wind Turbines',
  [WorkType.Barracks]: '38 - Barracks',
  [WorkType.JamesBayAndIsolatedWorksite]: '39 - Baie-James & Isolated Sites',
  [WorkType.OutsideProvinceQuebec]: '99 - Outside Province Quebec',
};

const workType = {
  [WorkType.Industrial]: 'Industrial (Isolated James Bay)',
  [WorkType.RegularLabor]: 'Regular Work',
  [WorkType.IndustrialSite]: 'Industrial Site',
  [WorkType.HeavyIndustries]: 'Heavy Industries',
  [WorkType.Pipeline]: 'Pipeline',
  [WorkType.PowerLineErection]: 'Electrical line - Erection',
  [WorkType.GeneralElectricPostLine]: 'Substation Electrical - General Line',
  [WorkType.CivilEngineeringAndRoads]: 'Civil Engineering And Roads',
  [WorkType.WindTurbine]: 'Wind Turbines',
  [WorkType.Barracks]: 'Barracks',
  [WorkType.JamesBayAndIsolatedWorksite]: 'James Bay And Isolated Worksite',
  [WorkType.OutsideProvinceQuebec]: 'Outside Province Quebec',
};

const serviceCallKind = {
  [ServiceCallKind.AccessoriesRental]: 'Accessories rental',
  [ServiceCallKind.Bare]: 'Bare',
  [ServiceCallKind.BareWithOperators]: 'Bare with operators',
  [ServiceCallKind.LaborRental]: 'Labor rental',
  [ServiceCallKind.LiftingPlan]: 'Lifting plan',
  [ServiceCallKind.LiftingTest]: 'Lifting test',
  [ServiceCallKind.OperatedHourly]: 'Operated hourly',
  [ServiceCallKind.OperatedMonthly]: 'Operated monthly',
  [ServiceCallKind.RollingEquipment]: 'Rolling equipment',
  [ServiceCallKind.LaborRentalOnOperated]: 'Labor rental on operated',
  [ServiceCallKind.LaborRentalOnBare]: 'Labor rental on bare',
  [ServiceCallKind.Storage]: 'Storage',
};

const workKind = {
  [WorkKind.AssemblyAndDisassembly]: '11 - Assembly and Disassembly',
  [WorkKind.BillableManTransport]: '18 - Man Transport (Billable)',
  [WorkKind.BillableBreakageRepair]: '13 - Billable breakage (Repair)',
  [WorkKind.BreakageRepair]: '12 - Breakage (Repair)',
  [WorkKind.BreakageWaiting]: '10 - Breakage (Waiting)',
  [WorkKind.CarKmAllowance]: '54 - Car Allowance',
  [WorkKind.CNesst]: '21 - C.N.E.S.S.T',
  [WorkKind.CraneAssistant]: '02 - Crane Assistant',
  [WorkKind.CraneAssistantNotOperatedRental]: '26 - Crane Assistant (Non-Operated)',
  [WorkKind.CraneOperator]: '01 - Crane Operator',
  [WorkKind.CraneOperatorsNonOperatedRental]: '25 - Crane Operator (Non-Operated)',
  [WorkKind.CraneTransport]: '07 - Crane Transport',
  [WorkKind.CraneTransportAssistant]: '37 - Crane Transport (assistant)',
  [WorkKind.CraneYardPreparation]: '06 - Yard Work',
  [WorkKind.Maintenance]: '05 - Maintenance',
  [WorkKind.Meal]: '51 - Meal',
  [WorkKind.Mechanic]: '14 - Mechanic (Yard or Garage)',
  [WorkKind.NonBillableAssemblyAndDisassembly]: '31 - Assembly and Disassembly (Non Billable)',
  [WorkKind.NonBillableManTransport]: '08 - Man Transport (Non Billable)',
  [WorkKind.NonBillableTwoManTransportDriver]: '47 - Driver (2-Man Transport, Non Billable)',
  [WorkKind.PaidHoursNotOperated]: '15 - Paid Hours not Operated',
  [WorkKind.Parking]: '59 - Parking',
  [WorkKind.Representation]: '24 - Representation',
  [WorkKind.RestOrMealPeriod]: '35 - Rest/Meal Period',
  [WorkKind.RoomAndBoardDay]: '52 - Room and Boarding (Day)',
  [WorkKind.RoomAndBoardTwoPlusWeeks]: '53 - Room and Boarding (2+ weeks)',
  [WorkKind.SiteManTransfer]: '28 - Site Man Transfer',
  [WorkKind.SpecialLeaves]: '22 - Special Leaves',
  [WorkKind.TaxiBusOrTolls]: '62 - Taxi, Bus, Toll',
  [WorkKind.TowerCrane]: '04 - Tower Crane',
  [WorkKind.Training]: '20 - Training',
  [WorkKind.TravelExpenses120AndMoreKm]: '58 - Travel Expenses 120 Km+',
  [WorkKind.TravelExpenses49To72Km]: '55 - Travel Expenses 49 to 72 Km',
  [WorkKind.TravelExpenses73To88Km]: '56 - Travel Expenses 73 to 88 Km',
  [WorkKind.TravelExpenses89To119Km]: '57 - Travel Expenses 89 to 119 Km',
  [WorkKind.TravelExpensesSiteTransfer]: '58 - Travel Expenses Site Transfer',
  [WorkKind.Trucker]: '03 - Trucker',
  [WorkKind.TwoManTransportDriver]: '17 - Driver (2-Man Transport)',
  [WorkKind.Waiting]: '09 - Waiting',
  [WorkKind.WorkPreparation]: '16 - Work Preparation',
  [WorkKind.RoomAndBoardPipeline]: '50 - Room and board (Pipeline)',

  // THE FOLLOWING BLOCK CONTAINS VALUES THAT ARE DEPRECATED AND ONLY USED FOR ALREADY EXISTING VALUES
  [WorkKind.Administration]: '0 - Administration',
  [WorkKind.Escort]: '19 - Escort',
  [WorkKind.Holiday]: '42 - Holiday',
  publicHoliday: '42 - Holiday',
  [WorkKind.SeverancePay]: '43 - Severance Pay',
  [WorkKind.Sickness]: '41 - Sickness',
  [WorkKind.TransferManToLoad]: '38 - Transfer Man to Load',
  [WorkKind.TravelExpenses61To90Km]: '60 - Travel Expenses 61 to 90 Km',
  [WorkKind.TravelExpenses91To119Km]: '61 - Travel Expenses 91 to 119 Km',
  [WorkKind.Vacation]: '40 - Vacation',

  // THE FOLLOWING BLOCK CONTAINS VALUES THAT HAVE BEEN RENAMED BUT TARGET THE CORRECT TRANSLATIONS
  [WorkKind.AutoKmAllowance]: '54 - Car Km Allowance',
  [WorkKind.CraneOperatorMenToLoad]: '18 - Man Transport (Billable)',
  [WorkKind.CraneOperatorTransportToLoad]: '17 - Driver (2-Man Transport)',
  [WorkKind.Csst]: '21 - C.N.E.S.S.T.',
  [WorkKind.MenTransport]: '08 - Man Transport (Non Billable)',
  operator: '01 - Crane Operator',
  [WorkKind.PresentationAtWork]: '16 - Work Preparation',
  [WorkKind.Repair]: '12 - Breakage (Repair)',
  [WorkKind.RoadBreakageWaiting]: '10 - Breakage (Waiting)',
  [WorkKind.RoomAndBoardWeek]: '53 - Room and Boarding (2+ Weeks)',
  [WorkKind.TaxiBusParking]: '59 - Parking',
};

const jobCode = {
  [JobCode.Administration]: 'Administration',
  [JobCode.Charpentier]: 'Carpenter',
  [JobCode.Chaudronnier]: 'Boilermaker',
  [JobCode.Electricien]: 'Electrician',
  [JobCode.Grutier]: 'Operator',
  [JobCode.GrutierClasseA]: 'Class A operator',
  [JobCode.GrutierClasseADeuxiemeHomme]: 'Second man class A operator',
  [JobCode.GrutierClasseB]: 'Class B operator',
  [JobCode.MecanicienAscenseur]: 'Elevator mechanic',
  [JobCode.MecanicienChantier]: 'Worksite mechanic',
  [JobCode.MecanicienMachineriesLourdes]: 'Heavy machinery mechanic',
  [JobCode.MonteurAcierStructure]: 'Structural steel fitter',
  [JobCode.OperateurEquipementLourd]: 'Heavy equipment operator',
  [JobCode.SoudeurMachinerieLourde]: 'Heavy equipment welder',
};

const ordreDeTravailStatus = {
  [OrdreDeTravailStatus.Upcoming]: 'Upcoming',
  [OrdreDeTravailStatus.Ongoing]: 'Ongoing',
  [OrdreDeTravailStatus.Cancelled]: 'Cancelled',
  [OrdreDeTravailStatus.Modified]: 'Modified',
  [OrdreDeTravailStatus.Finished]: 'Finished',
  [OrdreDeTravailStatus.Dispatching]: 'Dispatching',
  [OrdreDeTravailStatus.External]: 'External',
  [OrdreDeTravailStatus.Internal]: 'Internal',
};

const ordreDeTravailValidationsStatuses = {
  [OrdreDeTravailValidationStatus.DocumentsReceived]: 'Documents received',
  [OrdreDeTravailValidationStatus.Open]: 'Open',
  [OrdreDeTravailValidationStatus.Submitted]: 'Submitted',
  [OrdreDeTravailValidationStatus.Upcoming]: 'Upcoming',
  [OrdreDeTravailValidationStatus.Entering]: 'Entering',
  [OrdreDeTravailValidationStatus.Validated]: 'Validated',
  [OrdreDeTravailValidationStatus.Cancelled]: 'Cancelled',
  [OrdreDeTravailValidationStatus.Internal]: 'Internal',
  [OrdreDeTravailValidationStatus.External]: 'External',
};

const timeSheetStatus = {
  [DailyTimeSheetStatus.Completed]: 'Completed',
  [DailyTimeSheetStatus.Ongoing]: 'Ongoing',
  [DailyTimeSheetStatus.NotStarted]: 'Not Started',
};

const timeSheetEntryStatus = {
  [TimeSheetEntryStatus.Completed]: 'Completed',
  [TimeSheetEntryStatus.Ongoing]: 'Ongoing',
  [TimeSheetEntryStatus.Validated]: 'Validated',
  [TimeSheetEntryStatus.Submitted]: 'Submitted',
};

const contractAgreementStatus = {
  [ContractAgreementStatus.Created]: 'Upcoming',
  [ContractAgreementStatus.Ongoing]: 'Ongoing',
  [ContractAgreementStatus.Finished]: 'Finished',
  editing: 'Change in progress',
  unknown: 'Unknown',
};

const contractAgreementSubmitted = {
  submitted: 'Submitted',
  validated: 'Validated',
};

const replacedOperator = {
  replaced: 'Replaced',
};

const insufficientPermissions = {
  title: "You don't have the access level to see the content of this page",
  contactAdmin: 'Contact your administrator',
};

const travellingExpensesButtonGroup = {
  kmRange: '{{from}} to {{to}} km',
  numberOfHours_one: '{{count}} hour',
  numberOfHours_other: '{{count}} hours',
  errorLabel: 'Value must be between {{min}} and {{max}}',
};

const timeRangeTable = {
  from: 'From',
  to: 'To',
  poste: 'Poste',
  type: 'Type',
  quantity: 'Qty',
  workOrderNumber: 'Equi./WO',
  branch: 'Branch',
  gtNumber: '#GT',
  simple: 'Simple',
  singleAndAHalf: 'Half',
  double: 'Double',
  automaticPrime: 'Aut P.',
  variablePrime: 'Var P.',
  automaticPrimeLong: 'Auto Prime',
  variablePrimeLong: 'Vari Prime',
  rate: 'Rate',
  linkedTo: 'Linked To',
  footer: {
    total: 'Total',
    weeklyTotal: 'Weekly total',
  },
};

const splitTimeRangeOrPremiumModal = {
  title: 'Separate a time range',
  addNewTimeRange: 'Add a time range',
  warningTotalHoursQuantity: `Please note that the quantity of hours entered is different from the quantity of initials hours.`,
  errors: {
    emptyFields: `Time ranges are empty. Thank you for correcting.`,
    overlappingFields: `Some time ranges overlap. Thank you for correcting.`,
    incorrectRestOrMealTimeRange: `The time entered in a No break/No lunch is incorrect.`,
    minIsFifteenMinutes: `Time ranges must be a minimum of 15 minutes.`,
  },
  cancel: 'Cancel',
  split: 'Split',
};

const requiredValidation = {
  pensionRequired: 'Pension type is required',
  rangeShouldBeBetween: 'The value should be between {{from}} and {{to}}',
  rangeMaxLTE: 'The value must be less than or equal to {{value}}',
  rangeMinGTE: 'Value must be greater than or equal to {{value}}',
};

const messages = {
  concurrencyErrorMessage: 'The record has been modified by another user, please refresh to access the updated data.',
  saveSuccessMessage: 'Modifications saved successfully.',
  serviceCallModificationRequestSentSuccessfuly: 'Service call modification request sent successfully.',
};

const errorPage = {
  notFoundErrorTitle: '{{entityName}} not found.',
  notFoundErrorMessage: 'Please verify the number or your URL.',
  defaultErrorTitle: 'Unexpected error occurred.',
  defaultErrorMessage: 'Check your Internet connexion or ',
  backToList: 'Back to list',
  entityType: {
    [EntityTypeErrorEnum.Unknown]: 'Entity',
    [EntityTypeErrorEnum.OrdreDeTravail]: 'Ordre de travail',
    [EntityTypeErrorEnum.TimeSheet]: 'TimeSheet',
    [EntityTypeErrorEnum.Incompatibility]: 'Incompatibility',
    [EntityTypeErrorEnum.ContractAgreement]: 'Contract agreement',
  },
};

const addTimeRangeModal = {
  title: 'Add',
  addTimeRange: 'Time range',
  addCompilablePremium: 'Compilable premium',
  indemnityType: 'Indemnity type',
  errors: {
    required: 'Required',
    emptyFields: `You have empty fields`,
    toGreaterThanFrom: `The end time must be greater than the start time`,
    invalidPosteDeTravail: `Invalid Poste De Travail`,
    incorrectRestOrMealTimeRange: `The time entered in a No break/No lunch is incorrect`,
    invalidGtNumber: 'The GT Number is invalid',
    overlapsExistingTimeRange: 'The time range you are trying to add overlaps an existing time range',
  },
  tableHeader: {
    linkTo: 'Link to CA',
    from: 'From',
    to: 'To',
    quantity: 'Quantity',
    code: 'Poste',
    single: 'Single',
    singleAndHalf: 'Half',
    double: 'Double',
    variablePrime: 'Var Pr.',
    automaticPrime: 'Auto Pr.',
    rate: 'Rate',
    gtNumber: '#GT',
    workOrderNumber: 'Equi./WO',
  },
};

const operatorKind = {
  [CraneOperatorKind.AdditionalMan]: 'Additional man',
  [CraneOperatorKind.Electrician]: 'Electrician',
  [CraneOperatorKind.Engineer]: 'Engineer',
  [CraneOperatorKind.Helper]: 'Helper',
  [CraneOperatorKind.IndustrialDesigner]: 'Industrial designer',
  [CraneOperatorKind.Operator]: 'Operator',
  [CraneOperatorKind.Signalman]: 'Signalman',
  [CraneOperatorKind.SiteSupervisor]: 'Site supervisor',
  [CraneOperatorKind.TechnicalSupervisor]: 'Technical supervisor',
  [CraneOperatorKind.Welder]: 'Welder',
  [CraneOperatorKind.Trucker]: 'Trucker',
  [CraneOperatorKind.Mechanic]: 'Mechanic',
  [CraneOperatorKind.Rigger]: 'Rigger',
  [CraneOperatorKind.ReplacedOperator]: 'Replaced Operator',
};

const comments = {
  noData: 'No Comment',
};

const validationError = {
  title: 'Saving of {{type}} failed because of the following errors:',
  seeMore: 'See more',
  seeLess: 'See less',
  contractAgreement: 'Contract agreement',
  timesheet: 'Timesheet',
  gtMustExist: 'The GT number does not exist.',
  gtMustBeActive: 'The GT number is not active.',
  gtClientMustBeSameAsOtClient: 'The client linked to the GT # is different in the service call.',
  gtBranchMustBeSameAsOtBranch: 'The sales branch linked to the GT # is different in the OT.',
  mustBeFourDigits: 'Must be four digits',
};

const cancelled = {
  ordreDeTravail: 'The associated ordre de travail has been cancelled.',
};

const validationTags = {
  waitingForInformation: 'Waiting for info',
  returnForCorrection: 'Return for correction',
  submittedWithErrors: 'Submitted with error(s)',
};

export const common = {
  timeSheetEntryStatus,
  autocomplete,
  button,
  gridFooter,
  insufficientPermissions,
  navigation,
  serverConnectionErrorPanel,
  commonTableToolbar,
  savingErrorModal,
  cancelConfirmModal,
  somethingWentWrong,
  timeRangePicker,
  weekPicker,
  cancelEditWarningModal,
  workTypeWithCode,
  workType,
  workKind,
  serviceCallKind,
  ordreDeTravailStatus,
  timeSheetStatus,
  contractAgreementStatus,
  jobCode,
  travellingExpensesButtonGroup,
  timeRangeTable,
  splitTimeRangeOrPremiumModal,
  requiredValidation,
  contractAgreementSubmitted,
  replacedOperator,
  ordreDeTravailValidationsStatuses,
  messages,
  pendingNumber: 'Pending',
  addTimeRangeModal,
  errorPage,
  operatorKind,
  saveResult,
  attachmentResult,
  validationError,
  at: 'at',
  comments,
  cancelled,
  continuityFlags: validationTags,
};
