import { QueryClient } from '@tanstack/react-query';
import { defer, Params } from 'react-router-dom';
import { ordreDeTravailInfoQueryOptions } from '../../common/hooks/useGetOrdreDeTravailInfo';
import { OrdreDeTravailInfoRecord } from '../../common/models/models';
import { GetContractAgreementRecord } from '../../contractAgreement/models/shared/models';
import { operatorDailyTimeSheetIdsQueryOptions } from '../hooks/useGetOperatorDailyTimeSheetIds';
import { OrdreDeTravailSubmissionStatusRecord } from '../models/models';
import { ValidationParams } from '../params';

export type ContractAgreementLoaderData = {
  contractAgreement: { data?: GetContractAgreementRecord };
  operatorDailyTimeSheetIds: { data?: string[] };
  ordreDeTravailInfo: { data?: OrdreDeTravailInfoRecord };
  contractAgreementSubmissionRecord: { data: OrdreDeTravailSubmissionStatusRecord };
};

export const validationContractAgreementLoader =
  (queryClient: QueryClient) =>
  ({ params }: { params: Params<ValidationParams> }): ReturnType<typeof defer> => {
    const fetchOrdreDeTravailInfo = queryClient.fetchQuery({
      ...ordreDeTravailInfoQueryOptions(params.ordreDeTravailId!),
      staleTime: 1000 * 60 * 2, // 2 minutes
    });
    const fetchOperatorDailyTimeSheets = queryClient.fetchQuery({
      ...operatorDailyTimeSheetIdsQueryOptions(params.contractAgreementId!),
      staleTime: 1000 * 60 * 2, // 2 minutes
    });

    return defer({
      operatorDailyTimeSheetIds: fetchOperatorDailyTimeSheets,
      ordreDeTravailInfo: fetchOrdreDeTravailInfo,
    });
  };
